import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { getInitialsFromString } from "src/utils";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { localize } from "src/utils/l10n";
import { ActionMenu } from "src/components/ActionMenu";
import * as Style from 'src/utils/style';

import { IApplicationState } from "src/reducer";
import SpintrUser from "src/components/SpintrUser/SpintrUser";
import "./SpintrUserMenu.scss";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import { setAdminMode, setPopupForm } from "src/actions/ui";
import FormPopup from "../FormPopup/FormPopup";
import UserForm from "../UserForm/UserForm";

interface IOwnProps {
    user: any;
}

interface IStateProps {
    isAdministrator: boolean;
    isEditor: boolean;
    instance: any;
    currentUser: any;
    adminMode: boolean;
    dispatch?: any;
}

interface IUserMenuState {
    menuIsOpen: boolean;
    displayReloadDialog: boolean;
    displayChangeInstanceMessage: boolean;
}

interface IProps {
    firstNameOnly?: boolean;
    displaySubText?: boolean;
    bigCoin?: boolean;
    runIntroduction?: any;
}

type Props = IOwnProps & IStateProps & RouteComponentProps & IProps;

interface IActionMenuItem {
    icon?: string;
    key?: string;
    text: string;
    onClick?: () => void;
    href?: string;
    subMenuProps?: any;
    canCheck?: boolean;
    isChecked?: boolean;
    disabled?: boolean;
}

interface IActionMenuCategory {
    title?: string;
    items: IActionMenuItem[];
}



class SpintrUserMenu extends Component<Props, IUserMenuState> {
    private actionMenu = React.createRef<ActionMenu>();

    constructor(props: Props) {
        super(props);

        this.state = {
            menuIsOpen: false,
            displayReloadDialog: false,
            displayChangeInstanceMessage: false,
        };
    }

    getCategories() {
        let categories: IActionMenuCategory[] = [
            {
                items: [],
            }
        ];

        if (!this.props.currentUser.isFunctionUser) {
            categories[0].items.push({
                onClick: () => {
                    this.props.dispatch(setPopupForm({
                        popupFormKey: "Profile",
                        id: 0,
                        title: localize("Profilinstallningar"),
                        isDisplayed: true
                    }));
                },
                text: localize("Profilinstallningar"),
            });
        }

        categories[0].items.push({
            onClick: () => {
                if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.userAgent.indexOf("Trident") !== -1) {
                    document.execCommand("ClearAuthenticationCache");
                }

                localStorage.clear();

                window.location.assign("/api/v1/authentication/signout");
            },
            text: localize("LoggaUt"),
        });

        const adminItems = [];

        if (this.props.currentUser.isSuperAdmin ||
            (this.props.currentUser.isAdmin && this.props.currentUser.isFunctionUser)) {
            adminItems.push({
                onClick: () => {},
                text: localize("SUPER_INSTANCE") + ": " + this.props.instance.superInstanceName,
                disabled: true
            });
        }

        if (this.props.currentUser.isAdmin || this.props.currentUser.isFunctionUser) {
            adminItems.push({
                onClick: () => {},
                text: localize("CUSTOMER") + ": " + this.props.instance.name,
                disabled: true
            });
        }

        if (this.props.currentUser.isAdmin) {
            adminItems.push({
                onClick: () => {
                    this.props.dispatch(setAdminMode(!this.props.adminMode));
                    this.goTo("/");
                },
                text: this.props.adminMode ? localize("GO_TO_USER_MODE") : localize("GO_TO_ADMIN_MODE"),
            });
        } else if (this.props.currentUser.isFunctionUser) {
            adminItems.push({
                onClick: () => {
                    this.goTo("/imp/0");
                },
                text: localize("GO_TO_ADMIN_MODE"),
            });
        }

        if (adminItems.length > 0 ) {
            categories.push({
                title: localize("Administrator"),
                items: adminItems,
            });
        }

        return categories;
    }

    renderFormPopup() {
        return (
            <FormPopup popupFormKey="Profile">
                <UserForm id={this.props.currentUser.id} />
            </FormPopup>
        )
    }

    public render() {
        const imageSrc = this.props.user.imageUrl;

        const department = this.props.user.department;
        const departmentName = department?.name;

        let menuWrapBackgroundColor = "#FFFFFF";

        let textColor = getLightOrDarkColorBasedOnColor(menuWrapBackgroundColor,
            Style.getHexFromSpintrColor("p-white"),
            Style.getHexFromSpintrColor("p-dark-grey-1"));

        return (
            <div
                className="SpintrUserMenu icon-animation animation-pulse"
                onClick={(e) => {
                    e.preventDefault();
                    //@ts-ignore
                    ReactDOM.findDOMNode(this.actionMenu.current).click();
                }}
            >
                <div className="menuWrap" style={{
                    backgroundColor: menuWrapBackgroundColor
                }}>
                    <SpintrUser
                        imageInitials={getInitialsFromString(this.props.user.name)}
                        name={this.props.user.name}
                        subText={this.props.displaySubText ? departmentName : ""}
                        imageUrl={imageSrc}
                        firstNameOnly={this.props.firstNameOnly}
                        textColor={textColor}
                        greyBackground={menuWrapBackgroundColor === this.props.instance.color}
                    />
                    <div className="actions" id="submenu-actionmenu">
                        <div className="icon no-user-select">
                            <ActionMenu
                                ref={this.actionMenu}
                                isOpen={this.state.menuIsOpen}
                                categories={this.getCategories()}
                                icon="ChevronDown"
                                gapSpace={20}
                                renderButton={() => {
                                    return (
                                        <Visage2Icon hexColor={textColor} icon="arrow-right-3" size="small" />
                                    )
                                }}
                                iconColor={textColor}
                                disableHoverEffect
                            />
                        </div>
                    </div>
                </div>
                {this.renderFormPopup()}
            </div>
        );
    }

    private goTo(url: string): void {
        this.props.history.push(url);
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state) => {
    return {
        isAdministrator: false,
        isEditor: false,
        instance: state.instance.currentInstance,
        currentUser: state.user.currentUser,
        adminMode: state.ui.adminMode
    };
};

const SpintrUserMenuWithRouter = withRouter(SpintrUserMenu);

export default connect(mapStateToProps)(SpintrUserMenuWithRouter);
