import { Checkbox, DefaultButton, Dropdown, Pivot, PivotItem, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editInstance, fetchCurrentInstance } from 'src/actions/instance';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { validateRequiredTextField } from 'src/utils';
import { localize } from 'src/utils/l10n';
import FormControl from 'src/components/FormControl/FormControl';
import FormSection from 'src/components/FormSection/FormSection';
import Loader from 'src/components/Loader/Loader';
import ErrorMessagebar from 'src/components/Messagebars/ErrorMessagebar';
import SuccessMessagebar from 'src/components/Messagebars/SuccessMessagebar';

import './InstanceSettings.scss';
import { Label } from 'src/components/Label/Label';
import PhoenixColorPicker from 'src/components/PhoenixColorPicker/PhoenixColorPicker';
import { setConfirmPopup } from 'src/actions/popup';
import FileSelector, { IUploadModel } from '../FileSelector/FileSelector';
import getLanguages from 'src/utils/getLanguages';
import FormTokenizedObjectInput from '../FormTokenizedObjectInput/FormTokenizedObjectInput';
import { SpintrTypes } from 'src/typings';
import { setPopupForm } from 'src/actions/ui';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';

interface IProps {
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
}

interface IState {
    item: any;
    isLoading: boolean;
    saveSuccess: boolean;
    saveError: any[];
    enableFieldValidation: boolean;
    apiKey: string;
}

class InstanceSettings extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            item: {
                ...props.currentInstance,
                primaryColor: props.currentInstance.color,
                theme: !!props.currentInstance.theme ?
                    props.currentInstance.theme :
                    {
                        primaryColor: props.currentInstance.color
                    }
            },
            isLoading: false,
            saveSuccess: false,
            saveError: [],
            enableFieldValidation: false,
            apiKey: ""
        };
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoading: false,
        });
    };

    onSaveClick() {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            const payload = {
                ...this.state.item,
                theme: {
                    ...(this.state.item.theme || {}),
                    ...this.state.item
                },
                ...{ id: this.props.currentInstance.id }
            };

            let saveError: string[] = [];

            if (!payload.name) {
                saveError.push("DuMasteFyllaIEttNamn");
            }

            if (saveError.length > 0) {
                this.setState({
                    saveError: saveError,
                    isLoading: false
                });

                return;
            }

            api.post("/api/v1/instances", payload).then(() => {
                this.props.dispatch(fetchCurrentInstance());
                this.props.dispatch(setPopupForm({
                    popupFormKey: "Instance",
                    isDisplayed: false
                }));
                this.props.dispatch(setPopupForm({
                    popupFormKey: "InstanceSidebar",
                    isDisplayed: false
                }));
            }).catch(this.handleCatch.bind(this));
        });
    }

    onCancelClick() {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "Instance",
                isDisplayed: false
            }));
            this.props.dispatch(setPopupForm({
                popupFormKey: "InstanceSidebar",
                isDisplayed: false
            }));
        });
    }

    renderGeneralPart() {
        const languages = getLanguages();

        return (
            <FormSection>
                <FormControl>
                    <TextField
                        value={this.state.item.name}
                        className="textField"
                        label={localize("COMPANY_NAME")}
                        resizable={false}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                            this.setState({
                                item: {
                                    ...this.state.item,
                                    name: newValue
                                }
                            });
                        }}
                        required
                        aria-required
                        validateOnFocusOut
                        validateOnLoad={!!this.state.enableFieldValidation}
                        onGetErrorMessage={validateRequiredTextField}
                    />
                </FormControl>
                <FormControl>
                    <Dropdown
                        label={localize("Sprak")}
                        selectedKey={this.state.item.language.toString()}
                        required
                        onChange={(_e, v) => {
                            this.setState({
                                item: {
                                    ...this.state.item,
                                    language: Number(v.key)
                                }
                            });
                        }}

                        options={languages}
                        styles={{ dropdown: { width: 250 } }}
                    />
                </FormControl>
                <FormControl>
                    <FormTokenizedObjectInput
                        items={!!this.state.item.sender ? [this.state.item.sender] : []}
                        types={[SpintrTypes.UberType.User]}
                        label={localize("SENDER")}
                        url={"/api/v1/users"}
                        itemLimit={1}
                        onChange={(items) => {
                            if (!items || items.length === 0) {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        sender: undefined
                                    }
                                });

                                return;
                            }

                            let sender = items[0];

                            sender = {
                                ...sender,
                                key: !!sender.key ? sender.key : sender.id,
                                id: !!sender.id ? sender.id : sender.key
                            }

                            this.setState({
                                item: {
                                    ...this.state.item,
                                    sender
                                }
                            });
                        }}
                    />
                </FormControl>
                <FormSection>
                    <FormControl label={localize("SEND_SURVEYS_AS_SMS_OR_EMAIL")} className="survey-checkboxes">
                        <Checkbox
                            label={localize("SEND_AS_SMS")}
                            checked={!!this.state.item.sendSurveysWithSms}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        sendSurveysWithSms: checked
                                    }
                                });
                            }}
                        />
                        <Checkbox
                            label={localize("SEND_AS_EMAIL")}
                            checked={!!this.state.item.sendSurveysWithEmail}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        sendSurveysWithEmail: checked
                                    }
                                });
                            }}
                        />
                    </FormControl>
                </FormSection>
            </FormSection>
        )
    }

    renderColorsPart() {
        return (
            <div>
                <FormControl label={localize("IMPORT_APPEARANCE")}>
                    <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("ENTER_API_IMPORT")}</Label>
                    <div className="input-button-inline">
                        <TextField
                            value={this.state.apiKey}
                            placeholder={localize("API_KEY")}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                this.setState({
                                    apiKey: newValue
                                });
                            }} />
                        <PrimaryButton
                            disabled={!this.state.apiKey}
                            text={localize("IMPORT")}
                            onClick={() => {
                                if (!this.state.apiKey) {
                                    return;
                                }

                                this.props.dispatch(
                                    setConfirmPopup({
                                        isOpen: true,
                                        title: localize("IMPORT_APPEARANCE"),
                                        message: localize("IMPORT_APPEARANCE_TEXT"),
                                        onConfirm: async () => {
                                            this.setState({
                                                isLoading: true
                                            }, () => {
                                                api.get("/api/v1/instances/import-theme/" + encodeURIComponent(this.state.apiKey)).then(() => {
                                                    api.get("/api/v1/instances/this").then((response: any) => {
                                                        this.props.dispatch(editInstance({
                                                            ...response.data.theme,
                                                            id: this.props.currentInstance.id
                                                        }));

                                                        this.setState({
                                                            isLoading: false,
                                                            saveSuccess: true,
                                                            apiKey: "",
                                                            item: {
                                                                ...this.state.item,
                                                                ...response.data.theme
                                                            }
                                                        });
                                                    }).catch(this.handleCatch.bind(this));
                                                }).catch(this.handleCatch.bind(this));
                                            });

                                        },
                                    })
                                );
                            }} />
                    </div>
                </FormControl>
                <FormSection title={localize("PICTURES")}>
                    <FormControl label={localize("LOGOTYPE_COLOR")}>
                        <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("LOGO_USE")}</Label>
                        <FileSelector
                            isImage
                            fileUrl={this.state.item.colorLogoUrl}
                            onChange={(model: IUploadModel) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        colorLogoUrl: model.thumbnailUrl,
                                        colorImageTicket: model
                                    }
                                });
                            }} />
                    </FormControl>
                    <FormControl label={"Favicon"}>
                        <FileSelector
                            isImage
                            fileUrl={this.state.item.faviconUrl}
                            onChange={(model: IUploadModel) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        faviconUrl: model.thumbnailUrl,
                                        faviconTicket: model
                                    }
                                });
                            }} />
                    </FormControl>
                    {/* <FormControl label={localize("INLOGGNING")}>
                        <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("INLOGGNING_USE")}</Label>
                        <FileSelector
                            isImage
                            fileUrl={this.state.item.loginImageUrl}
                            onChange={(model: IUploadModel) => {
                                console.log(model);
                            }} />
                    </FormControl> */}
                </FormSection>
                <FormSection title={localize("COLORS")}>
                    <FormControl label={localize("PRIMARY_COLOR")}>
                        <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("PRIMARY_COLOR_USE")}</Label>
                        <PhoenixColorPicker
                            color={this.state.item.primaryColor}
                            onChange={(color) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        primaryColor: color,
                                        color,
                                        theme: {
                                            ...this.state.item.theme,
                                            primaryColor: color,
                                            color,
                                        }
                                    }
                                });
                            }} />
                    </FormControl>
                    {/* <FormControl>
                        <Checkbox
                            label={localize("USE_HEADER_COLOR")}
                            checked={this.state.item.useColorHeader}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        useColorHeader: checked,
                                    },
                                });
                            }}
                        />
                    </FormControl>
                    {
                        this.state.item.useColorHeader && (
                            <div>
                                <FormControl label={localize("HEADER")}>
                                    <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("HEADER_COLOR_USE")}</Label>
                                    <PhoenixColorPicker
                                        color={this.state.item.headerColor}
                                        onChange={(color) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    headerColor: color,
                                                    theme: {
                                                        ...this.state.item.theme,
                                                        headerColor: color
                                                    }
                                                }
                                            });
                                        }} />
                                </FormControl>
                                <FormControl label={localize("SECONDARY_COLOR")}>
                                    <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("SECONDARY_COLOR_USE")}</Label>
                                    <PhoenixColorPicker
                                        color={this.state.item.accentColor}
                                        onChange={(color) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    accentColor: color,
                                                    theme: {
                                                        ...this.state.item.theme,
                                                        accentColor: color
                                                    }
                                                }
                                            });
                                        }} />
                                </FormControl>
                                <FormControl>
                                    <Checkbox
                                        label={localize("USE_SECONDARY_HEADER_COLOR")}
                                        checked={this.state.item.useSecondaryColorHeader}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                            this.setState({
                                                item: {
                                                    ...this.state.item,
                                                    useSecondaryColorHeader: checked,
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                                {
                                    this.state.item.useSecondaryColorHeader && (
                                        <FormControl label={localize("SECONDARY_HEADER_COLOR")}>
                                            <PhoenixColorPicker
                                                color={this.state.item.headerSecondaryColor}
                                                onChange={(color) => {
                                                    this.setState({
                                                        item: {
                                                            ...this.state.item,
                                                            headerSecondaryColor: color,
                                                            theme: {
                                                                ...this.state.item.theme,
                                                                headerSecondaryColor: color
                                                            }
                                                        }
                                                    });
                                                }} />
                                        </FormControl>
                                    )
                                }
                            </div>
                        )
                    } */}
                </FormSection>
                {/* <FormSection title={localize("INSTANCE_FONTS")}>
                    <FormControl label={localize("INSTANCE_FONT_HEADLINE")}>
                        <Label size="body-3" color="p-mid-grey-4" className="form-info-text">{localize("LOGO_USE")}</Label>
                        <FileSelector
                            isFont
                            fileUrl={this.state.item.fontUrl}
                            fileName={this.state.item.fontName}
                            onChange={(model: IUploadModel) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        fontUrl: model.url,
                                        fontName: model.originalFileName
                                    }
                                });
                            }} />
                    </FormControl>
                    <FormControl>
                        <Checkbox
                            label={localize("USE_UPPERCASE_HEADERS")}
                            checked={this.state.item.useUppercaseMainMenu}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        useUppercaseMainMenu: checked,
                                    },
                                });
                            }}
                        />
                    </FormControl>
                </FormSection> */}
            </div>
        )
    }

    renderAdminPart() {
        return (
            <div>
                <FormSection>
                    <FormControl>
                        <TextField
                            value={this.state.item.customerNumber}
                            className="textField"
                            label={localize("CUSTOMER_NUMBER")}
                            resizable={false}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        customerNumber: newValue
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    <div className="form-row">
                        <FormControl>
                            <TextField
                                value={this.state.item.industry}
                                className="textField"
                                label={localize("INDUSTRY")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            industry: newValue
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.year}
                                className="textField"
                                label={localize("FOUNDED_YEAR")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            year: newValue
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="form-row">
                        <FormControl>
                            <TextField
                                value={this.state.item.numberOfEmployees}
                                className="textField"
                                label={localize("NUMBER_OF_EMPLOYEES")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            numberOfEmployees: newValue
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={this.state.item.numberOfBusinessUnits}
                                className="textField"
                                label={localize("NUMBER_OF_BUSINESS_UNITS_SHORT")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            numberOfBusinessUnits: newValue
                                        }
                                    });
                                }}
                            />
                        </FormControl>
                    </div>
                    <FormControl>
                        <FormTokenizedObjectInput
                            items={this.state.item.customerRepresentatives}
                            types={[SpintrTypes.UberType.User]}
                            label={localize("CUSTOMER_REPRESENTATIVES")}
                            url={"/api/v1/users"}
                            customParams={{
                                onlyAdministrators: true
                            }}
                            onChange={(items) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        customerRepresentatives: items.map(x => {
                                            return {
                                                ...x,
                                                key: !!x.key ? x.key : x.id,
                                                id: !!x.id ? x.id : x.key
                                            }
                                        })
                                    }
                                });
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <Toggle
                            label="AI"
                            checked={this.state.item.enableAI}
                            onChange={(ev, val) => {
                                this.setState((prevState) => ({
                                    item: {
                                        ...prevState.item,
                                        enableAI: val
                                    }
                                }));
                            }}
                        />
                    </FormControl>
                    {/* <FormControl>
                        <Dropdown
                            label={localize("CALCULATION_MODEL")}
                            selectedKey={this.state.item.calculationModel.toString()}
                            required
                            onChange={(_e, v) => {
                                this.setState({
                                    item: {
                                        ...this.state.item,
                                        calculationModel: Number(v.key)
                                    }
                                });
                            }}
                            options={[{
                                key: "0",
                                text: localize("CALCULATION_MODEL_0")
                            }, {
                                key: "1",
                                text: localize("CALCULATION_MODEL_1")
                            }]}
                            styles={{ dropdown: { width: 250 } }}
                        />
                    </FormControl> */}
                </FormSection>
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="InstanceSettings fullWidthForm">
                {this.state.saveSuccess && (
                    <SuccessMessagebar
                        message={localize("SparningenLyckades")}
                        onDismiss={() => {
                            this.setState({
                                saveSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.saveError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                )}
                <PhoenixForm onSubmit={this.onSaveClick.bind(this)} data={this.state.item}>
                    <Pivot>
                        <PivotItem headerText={localize("Allmant")}>
                            {this.renderGeneralPart()}
                        </PivotItem>
                        {/* <PivotItem headerText={localize("Vektorer")}>
                            {this.renderVectorsPart()}
                        </PivotItem> */}
                        <PivotItem headerText={localize("APPEARENCE")}>
                            {this.renderColorsPart()}
                        </PivotItem>
                        {
                            this.props.currentUser.isAdmin && (
                                <PivotItem headerText={localize("Administrator")}>
                                    {this.renderAdminPart()}
                                    {/* {this.renderVectorsPart()} */}
                                </PivotItem>
                            )
                        }
                    </Pivot>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={this.onCancelClick.bind(this)} text={localize("Avbryt")} />
                        <PrimaryButton type={"submit"} text={localize("Spara")} />
                    </Stack>
                </PhoenixForm>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance
});

export default connect(mapStateToProps)(InstanceSettings);
