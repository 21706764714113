import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setConfirmPopup } from 'src/actions/popup';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import { listActionMenuWidth } from 'src/utils/style';
import { ActionMenu } from 'src/components/ActionMenu';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpintrList from 'src/components/SpintrList/SpintrList';
import './Coworkers.scss';
import { DefaultButton, FontIcon, PrimaryButton } from '@fluentui/react';
import SpintrUser from '../SpintrUser/SpintrUser';
import SuccessMessagebar from '../Messagebars/SuccessMessagebar';
import Page from '../Page/Page';
import { SpintrTypes } from 'src/typings';
import HeaderContextSearch from '../HeaderContextSearch/HeaderContextSearch';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import FormPopup from '../FormPopup/FormPopup';
import UserForm from '../UserForm/UserForm';
import { IPopupFormState } from 'src/reducers/ui';
import { setPopupForm } from 'src/actions/ui';
import ImportUsers from '../ImportUsers/ImportUsers';
import Notifications from '../Notifications/Notifications';

interface IRouteParams {
    id?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    history: any;
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    popupFormState?: IPopupFormState;
    importPopupFormState?: IPopupFormState;
    adminMode?: boolean;
}

interface IState {
    items: any[];
    totalCount: number;
    isLoading: boolean;
    includeDeleted: boolean;
    successMessage?: string;
}

class Coworkers extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        const id = parseInt(props.match.params.id, 10);

        if (id) {
            this.props.dispatch(setPopupForm({
                popupFormKey: "User",
                id: id,
                instanceId: this.props.currentInstance.id,
                title: localize("EDIT_USER"),
                isDisplayed: true
            }));
        }

        this.state = {
            items: [],
            totalCount: 0,
            isLoading: true,
            includeDeleted: false,
            successMessage: ""
        };
    }

    toggleDelete(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("Aterstall")) :
                    (localize("TaBort")),
                message: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost")) :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost")),
                onConfirm: async () => {
                    api.delete("/api/v1/users/toggledelete/" + item.id).then(() => {
                        this.setState({
                            items: this.state.items.map((i: any) => {
                                return {
                                    ...i,
                                    deleted: i.id === item.id ?
                                        !i.deleted :
                                        i.deleted
                                }
                            })
                        });
                    });
                },
            })
        );
    }

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup
                popupFormKey="User"
                onClose={() => {
                    //@ts-ignore
                    this.listRef.current.reFetch();

                    if (this.props.match.params.id) {
                        this.props.history.push(this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf('/')));
                    }
                }}>
                <UserForm
                    id={this.props.popupFormState.id}
                    instanceId={this.props.popupFormState.instanceId}
                    chooseInstance={this.props.popupFormState.chooseInstance}
                />
            </FormPopup>
        )
    }

    renderImportFormPopup() {
        if (!this.props.importPopupFormState) {
            return null;
        }

        return (
            <FormPopup
                popupFormKey="ImportUsers"
                onClose={() => {
                    //@ts-ignore
                    this.listRef.current.reFetch();
                }}>
                <ImportUsers />
            </FormPopup>
        )
    }

    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)}>
                {this.renderContent()}
                {this.renderFormPopup()}
                {this.renderImportFormPopup()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderContextSearch searchKey="Coworkers" placeholder={localize("SEARCH_PLACEHOLDER_USERS")} />
                </div>
                <div className="header-right">
                    <DefaultButton
                        text={localize("Alternativ")}
                        //iconProps={addIcon}
                        menuProps={{
                            items: [{
                                key: "showDeleted",
                                text: this.state.includeDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        includeDeleted: !this.state.includeDeleted
                                    }, () => {
                                        //@ts-ignore
                                        this.listRef.current.reFetch();
                                    });
                                },
                            },
                            ...(!this.props.adminMode ? [{
                                key: "importUsers",
                                text: localize("IMPORT_USERS"),
                                onClick: () => {
                                    this.props.dispatch(setPopupForm({
                                        popupFormKey: "ImportUsers",
                                        id: 0,
                                        title: localize("IMPORT_USERS"),
                                        isDisplayed: true
                                    }));
                                },
                            // }, {
                            //     key: "targetGroups",
                            //     text: localize("RedigeraMalgrupper"),
                            //     onClick: () => {
                            //         this.props.history.push("/target-groups");
                            //     },
                            }] : [])]
                        }}
                    />
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "User",
                                id: 0,
                                title: localize("CREATE_USER"),
                                isDisplayed: true,
                                chooseInstance: this.props.adminMode,
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("CREATE_USER")}
                    </PrimaryButton>
                    {!this.props.adminMode && (
                        <Notifications />
                    )}
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <div className="Coworkers">
                {!!this.state.successMessage && (
                    <SuccessMessagebar
                        message={this.state.successMessage}
                        onDismiss={() => {
                            this.setState({
                                successMessage: undefined
                            });
                        }}
                    />
                )}
                <PageHeader
                    title={localize("USERS")}
                    hits={this.state.totalCount} />
                <SpintrList
                    //id="coworkers"
                    ref={this.listRef}
                    searchKey="Coworkers"
                    disableCommandBar
                    // history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/v1/users", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: this.state.includeDeleted,
                                        isAscending: isAscending,
                                        searchQuery: searchQuery,
                                        take: take,
                                        skip: skip,
                                        getAllInstances: this.props.adminMode
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    let items = response.data.items.map((obj) => {
                                        return obj;
                                    });

                                    resolve({
                                        data: items,
                                        totalCount: response.data.totalCount
                                    });

                                    this.setState({
                                        items: items,
                                        totalCount: response.data.totalCount
                                    });
                                });
                        });
                    }}
                    data={{
                        data: this.state.items,
                        totalCount: this.state.totalCount
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (item) => {
                                return (
                                    <div className="user-item">
                                        <SpintrUser
                                            name={item.name}
                                            imageUrl={item.imageUrl}
                                            subText={(item.targetGroups || []).map((tg: any) => {
                                                return tg.name;
                                            }).join(", ")} />

                                        {item.deleted && <FontIcon className="lock-icon" iconName="LockSolid" />}
                                    </div>
                                );
                            },
                        },
                        ...(this.props.adminMode ? [{
                            name: localize("CUSTOMER"),
                            fieldName: "customerName",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {item.customerName}
                                    </span>
                                );
                            },
                        }] : []),
                        {
                            name: localize("Skapad"),
                            fieldName: "createdDate",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {moment(item.createdDate).format("lll")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("Status"),
                            fieldName: "deleted",
                            minWidth: 150,
                            onRender: (item) => {
                                if (item.instanceDeleted) {
                                    return (
                                        <span>
                                            {localize("INSTANCE_DELETED")}
                                        </span>
                                    )
                                }

                                return (
                                    <span>
                                        {localize(item.deleted ? "Borttagen" : "Aktiv")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("Roll"),
                            fieldName: "role",
                            minWidth: 100,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {localize(item.isAdmin ? "Administrator" : item.canLogIn ? "USER" : "Mottagare_Singular")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                let items = [{
                                    text: localize("Redigera"),
                                    onClick: () => {
                                        this.props.dispatch(setPopupForm({
                                            popupFormKey: "User",
                                            id: item.id,
                                            instanceId: item.instanceId,
                                            title: localize("EDIT_USER"),
                                            isDisplayed: true
                                        }));
                                    }
                                }, {
                                    text: item.deleted ? localize("Aterstall") : localize("TaBort"),
                                    onClick: () => this.toggleDelete(item),
                                }];

                                if (item.isEligableForNewEmailCode && this.props.currentUser.isAdmin) {
                                    items.push({
                                        text: localize("SEND_NEW_CODE"),
                                        onClick: () => {
                                            api.post("/api/v1/users/" + item.id + "/send-email-code").then(() => {
                                                this.setState({
                                                    successMessage: localize("SEND_NEW_CODE_RESULT"),
                                                    items: this.state.items.map((i: any) => {
                                                        return {
                                                            ...i,
                                                            isEligableForNewEmailCode: i.id === item.id ?
                                                                !i.isEligableForNewEmailCode :
                                                                i.isEligableForNewEmailCode
                                                        }
                                                    })
                                                });
                                            }).catch(() => { });
                                        }
                                    });
                                }

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: items
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    isDescending={false}
                    orderByColumn="name"
                ></SpintrList>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "User"),
    importPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "ImportUsers"),
});

export default withRouter(connect(mapStateToProps)(Coworkers));