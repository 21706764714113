import { DefaultButton } from '@fluentui/react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setConfirmPopup } from 'src/actions/popup';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';
import { listActionMenuWidth } from 'src/utils/style';
import { ActionMenu } from 'src/components/ActionMenu';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpintrList from 'src/components/SpintrList/SpintrList';

import './Surveys.scss';
import { SpintrTypes } from 'src/typings';
import SuccessMessagebar from '../Messagebars/SuccessMessagebar';
import ErrorMessagebar from '../Messagebars/ErrorMessagebar';
import Page from '../Page/Page';
import HeaderContextSearch from '../HeaderContextSearch/HeaderContextSearch';
import { setAdminMode, setPopupForm } from 'src/actions/ui';
import { IPopupFormState } from 'src/reducers/ui';
import FormPopup from '../FormPopup/FormPopup';
import Notifications from '../Notifications/Notifications';
import StatusBall from '../StatusBall/StatusBall';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import { setActiveProjectId } from 'src/actions/project';
import SurveyReminderForm from '../SurveyReminderForm/SurveyReminderForm';

interface IProps extends RouteComponentProps {
    history: any;
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    popupFormState?: IPopupFormState;
    reminderPopupFormState?: IPopupFormState;
    adminMode?: boolean;
}

interface IState {
    items: any[];
    totalCount: number;
    isLoading: boolean;
    includeDeleted: boolean;
    item: any;
    isLoadingModal: boolean;
    userCount: any;
    copyLinkSuccess: boolean;
    copyLinkError: any[];
    projectId: number;
}

class Surveys extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrTypes.WrappedComponentType<typeof SpintrList>>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            items: [],
            totalCount: 0,
            isLoading: true,
            includeDeleted: false,
            item: {
                isAnonymous: true,
                sendWithSms: true,
                sendWithEmail: true,
            },
            isLoadingModal: false,
            userCount: {
                totalCount: 0,
                emailCount: 0,
                smsCount: 0,
            },
            copyLinkSuccess: false,
            copyLinkError: [],
            projectId: 0
        };
    }

    componentDidMount(): void {
        this.getUserCount();

        // if (window.location.pathname.indexOf("/create") > -1) {
        //     this.setState({
        //         showModal: true
        //     });
        // }
    }

    toggleDelete(item: any) {
        this.props.dispatch(
            setConfirmPopup({
                isOpen: true,
                title: item.deleted ?
                    (localize("ArDuSakerAttDuVillAterstallaDennaPost") + "?") :
                    (localize("ArDuSakerPaAttDuVillTaBortDennaPost") + "?"),
                onConfirm: async () => {
                    // api.delete("/api/v1/users/toggledelete/" + item.id).then(() => {
                    //     this.setState({
                    //         items: this.state.items.map((item: any) => {
                    //             return {
                    //                 ...item,
                    //                 deleted: item.id === item.id ?
                    //                     !item.deleted :
                    //                     item.deleted
                    //             }
                    //         })
                    //     });
                    // });
                },
            })
        );
    }

    getUserCount() {
        const targetGroups = !!this.state.item &&
            !!this.state.item.targetGroups ?
            this.state.item.targetGroups :
            [];

        const targetGroupIds = targetGroups.map((item: any, index: number) => {
            return item.id;
        });

        let queryString = "";

        if (!!targetGroupIds &&
            targetGroupIds.length > 0) {
            queryString = "?";

            for (let id of targetGroupIds) {
                queryString += ("targetGroupIds=" + id + "&");
            }

            queryString = queryString.substring(0, queryString.length - 1);
        }

        api.get("/api/v1/targetgroups/usercount" + queryString).then((response: any) => {
            this.setState({
                userCount: response.data
            });
        }).catch(this.handleCatch.bind(this));
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            isLoadingModal: false,
        });
    };

    renderSurveyReminderPopup() {
        if (!this.props.reminderPopupFormState) {
            return null;
        }

        return (
            <FormPopup
                popupFormKey="SurveyReminder"
                onClose={() => {
                    //@ts-ignore
                    this.listRef.current.reFetch();
                }}>
                <SurveyReminderForm />
            </FormPopup>
        )
    }

    render() {
        return (
            <Page renderHeader={this.renderHeader.bind(this)}>
                {this.renderContent()}
                {this.renderSurveyReminderPopup()}
            </Page>
        )
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <HeaderContextSearch searchKey="Surveys" placeholder={localize("SEARCH_PLACEHOLDER_SURVEYS")} />
                </div>
                <div className="header-right">
                    <DefaultButton
                        text={localize("Alternativ")}
                        //iconProps={addIcon}
                        menuProps={{
                            items: [{
                                key: "showDeleted",
                                text: this.state.includeDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        includeDeleted: !this.state.includeDeleted
                                    }, () => {
                                        //@ts-ignore
                                        this.listRef.current.reFetch();
                                    });
                                },
                            }]
                        }}
                    />
                    {!this.props.adminMode && (
                        <Notifications />
                    )}
                </div>
            </div>
        )
    }

    renderContent() {
        const screenWidth = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

        const limitColumns = screenWidth < 1450;

        return (
            <div className="Surveys">
                <PageHeader
                    title={localize("SURVEYS")}
                    hits={this.state.totalCount} />
                {this.state.copyLinkSuccess && (
                    <SuccessMessagebar
                        message={localize("COPY_LINK_SUCCESS")}
                        onDismiss={() => {
                            this.setState({
                                copyLinkSuccess: false
                            });
                        }}
                    />
                )}
                {this.state.copyLinkError.length > 0 && (
                    <ErrorMessagebar
                        errorList={this.state.copyLinkError}
                        onDismiss={() => {
                            this.setState({
                                copyLinkError: [],
                            });
                        }}
                    />
                )}
                <SpintrList
                    ref={this.listRef}
                    searchKey="Surveys"
                    disableCommandBar
                    // history={this.props.history}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get("/api/v1/surveys", {
                                    params: {
                                        orderByColumn: columnId,
                                        includeDeleted: this.state.includeDeleted,
                                        isAscending: isAscending,
                                        searchQuery: searchQuery,
                                        take: take,
                                        skip: skip,
                                        getAllInstances: this.props.adminMode
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    let items = response.data.items.map((obj) => {
                                        return obj;
                                    });

                                    resolve({
                                        data: items,
                                        totalCount: response.data.totalCount
                                    });

                                    this.setState({
                                        items: items,
                                        totalCount: response.data.totalCount
                                    });
                                });
                        });
                    }}
                    data={{
                        data: this.state.items,
                        totalCount: this.state.totalCount
                    }}
                    columns={[
                        {
                            name: localize("Skapad"),
                            fieldName: "createdDate",
                            onRender: (item) => {
                                return (
                                    <UnstyledButton
                                        className={"linkFGColor"}
                                        onClick={() => {
                                            if (this.props.adminMode) {
                                                if (item.instanceId === this.props.currentInstance.id) {
                                                    this.props.dispatch(setAdminMode(false));
                                                    this.props.dispatch(setActiveProjectId(item.projectId));
                                                    this.props.history.push("/");
                                                } else {
                                                    this.props.dispatch(setConfirmPopup({
                                                        isOpen: true,
                                                        title: localize("SWITCH_INSTANCE"),
                                                        message: localize("SWITCH_INSTANCE_MSG").replace("{0}", item.customerName),
                                                        onConfirm: () => {
                                                            this.props.history.push("/imp/" + item.instanceId);
                                                        }
                                                    }));
                                                }
                                            } else {
                                                this.props.dispatch(setActiveProjectId(item.projectId));
                                                this.props.history.push("/");
                                            }
                                        }}>
                                        <span style={{
                                            ...(item.deleted ? { textDecorationLine: "line-through" } : {}),
                                        }}>{moment(item.createdDate).format("lll")}</span>
                                    </UnstyledButton>
                                );
                            }
                        },
                        ...(this.props.adminMode ? [{
                            name: localize("CUSTOMER"),
                            fieldName: "customerName",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {item.customerName}
                                    </span>
                                );
                            },
                        }] : []),
                        {
                            name: localize("PROJECT"),
                            fieldName: "projectName",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {item.projectName}
                                    </span>
                                );
                            }
                        },
                        {
                            name: localize("VECTORS"),
                            minWidth: 100,
                            fieldName: "vectorCount"
                        },
                        // (!limitColumns ? {
                        //     name: localize("LAST_DISPATCH"),
                        //     fieldName: "lastDispatchDate",
                        //     minWidth: 200,
                        //     onRender: (item) => {
                        //         if (!item.lastDispatchDate) {
                        //             return null;
                        //         }

                        //         return (
                        //             <span>
                        //                 {moment(item.lastDispatchDate).format("YYYY-MM-DD HH:mm")}
                        //             </span>
                        //         );
                        //     },
                        // } : undefined),
                        {
                            name: localize("AntalSvar"),
                            fieldName: "userAnswerCount",
                            minWidth: 100,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {item.userAnswerCount + localize("AvTotalt") + item.userTotalCount}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("Status"),
                            fieldName: "status",
                            minWidth: 150,
                            onRender: (item) => {
                                if (item.instanceDeleted) {
                                    return (
                                        <span>
                                            {localize("INSTANCE_DELETED")}
                                        </span>
                                    )
                                }

                                if (item.projectStatus !== 0) {
                                    return (
                                        <span>
                                            {localize("PROJECT_STATUS") + ": " + localize("PROJECT_STATUS_" + item.projectStatus)}
                                        </span>
                                    )
                                }

                                return (
                                    <div>
                                        <StatusBall status={item.status} />
                                        <span>
                                            {localize("SURVEY_STATUS_" + item.status)}
                                        </span>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "",
                            minWidth: listActionMenuWidth,
                            maxWidth: listActionMenuWidth,
                            onRender: (item: any) => {
                                const isActive = !item.isClosed && !item.deleted;

                                if (!isActive) {
                                    return null;
                                }

                                const items = [{
                                    text: localize("COPY_SHAREABLE_LINK"),
                                    onClick: () => {
                                        const link = window.location.origin + "/survey/s/" + item.externalId;
                                        let result = false;

                                        try {
                                            if (!!navigator &&
                                                !!navigator.clipboard &&
                                                !!navigator.clipboard.writeText) {
                                                navigator.clipboard.writeText(link);
                                                result = true;
                                            }

                                            if (!result &&
                                                !!navigator &&
                                                !!navigator.clipboard &&
                                                //@ts-ignore
                                                !!navigator.clipboard.setData) {
                                                //@ts-ignore
                                                navigator.clipboard.setData(link);
                                                result = true;
                                            }
                                        } catch (err) { }

                                        if (result) {
                                            this.props.dispatch(setConfirmPopup({
                                                isOpen: true,
                                                title: localize("COPY_LINK_SUCCESS"),
                                                message: localize("COPY_LINK_SUCCESS_INFO"),
                                                onConfirm: () => {}
                                            }));
                                        } else {
                                            const errorMessage = localize("COPY_FAILED") + link;

                                            this.setState({
                                                copyLinkSuccess: false,
                                                copyLinkError: [errorMessage]
                                            });
                                        }
                                    }
                                }];

                                if (!item.isAnonymous &&
                                    item.userAnswerCount < item.userTotalCount) {
                                    items.push({
                                        text: localize("SEND_REMINDER"),
                                        onClick: () => {
                                            this.setState({
                                                item: item
                                            }, () => {
                                                this.props.dispatch(setPopupForm({
                                                    popupFormKey: "SurveyReminder",
                                                    id: item.id,
                                                    title: localize("SEND_REMINDER"),
                                                    isDisplayed: true,
                                                    remainingUsers: item.userTotalCount - item.userAnswerCount,
                                                }));
                                            });
                                        }
                                    });
                                }

                                if (items.length === 0) {
                                    return null;
                                }

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items,
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ].filter(x => !!x)}
                    isDescending={true}
                    orderByColumn="createdDate"
                ></SpintrList>
            </div >
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Survey"),
    reminderPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "SurveyReminder"),
    adminMode: state.ui.adminMode
});

export default withRouter(connect(mapStateToProps)(Surveys));