import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from 'src/api/SpintrApi';
import { IApplicationState } from 'src/reducer';
import { localize } from 'src/utils/l10n';

import './ImportUsers.scss';
import { Checkbox, DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import SpintrLoader from '../Loader/Loader';
import FormControl from '../FormControl/FormControl';
import FormSection from '../FormSection/FormSection';
import { Label } from '../Label/Label';
import FileSelector, { IUploadModel } from '../FileSelector/FileSelector';
import ErrorMessagebar from '../Messagebars/ErrorMessagebar';
import { IPopupFormState } from 'src/reducers/ui';
import { setPopupForm } from 'src/actions/ui';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';

interface IProps extends RouteComponentProps {
    history: any;
    currentInstance?: any;
    currentUser?: any;
    dispatch?: (Action) => void;
    popupFormState?: IPopupFormState;
}

interface IState {
    items: any[];
    totalCount: number;
    isLoading: boolean;
    includeDeleted: boolean;
    showModal: boolean;
    isLoadingModal: boolean;
    import: any;
    saveSuccess: boolean;
    saveError: any[];
    successMessage?: string;
}

class ImportUsers extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            items: [],
            totalCount: 0,
            isLoading: true,
            includeDeleted: false,
            showModal: false,
            isLoadingModal: false,
            saveSuccess: false,
            saveError: [],
            import: {
                mode: 0
            },
        };
    }

    render() {
        return (
            <div>
                <PhoenixForm onSubmit={this.import} data={this.state.import}>
                    {this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    {
                        this.state.isLoadingModal && (
                            <SpintrLoader />
                        )
                    }
                    {
                        !this.state.isLoadingModal && (
                            <div>
                                <FormControl>
                                    <Label size="body-2" color="p-mid-grey-4">{localize("CHOOSE_HOW_TO_IMPORT_USERS_TEXT") + "."}</Label>
                                </FormControl>
                                <FormSection>
                                    <FormControl label={localize("CHOOSE_HOW_TO_IMPORT_USERS")}>
                                        {/* <div className="dispatch-info">
                                            <Label size="body-2" color="p-mid-grey-4">{localize("CHOOSE_HOW_TO_IMPORT_USERS")}</Label>
                                        </div>
                                    </FormControl> */}
                                        <FormControl>
                                            <Checkbox
                                                label={localize("IMPORT_WITH_CSV")}
                                                checked={this.state.import.mode === 0}
                                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                    this.setState({
                                                        import: {
                                                            ...this.state.import,
                                                            mode: 0
                                                        }
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Checkbox
                                                label={localize("IMPORT_FROM_SPINTR")}
                                                checked={this.state.import.mode === 1}
                                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                                    this.setState({
                                                        import: {
                                                            ...this.state.import,
                                                            mode: 1
                                                        }
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                        {this.state.import.mode === 0 && (
                                            <div>
                                                <FormControl label={localize("UPLOAD_CSV")}>
                                                    <FileSelector
                                                        isCsv
                                                        uploadFolder={3}
                                                        fileUrl={this.state.import.fileUrl}
                                                        fileName={this.state.import.fileName}
                                                        onChange={(model: IUploadModel) => {
                                                            this.setState({
                                                                import: {
                                                                    ...this.state.import,
                                                                    fileUrl: model.url,
                                                                    fileName: model.originalFileName,
                                                                    ticket: model
                                                                }
                                                            });
                                                        }} />
                                                </FormControl>
                                                <a href="/example.csv" target="_blank">{localize("DOWNLOAD_EXAMPLE_FILE")}</a>
                                            </div>
                                        )}
                                        {this.state.import.mode === 1 && (
                                            <div>
                                                <FormControl>
                                                    <TextField
                                                        value={this.state.import.apiKey}
                                                        placeholder={localize("API_KEY") + "..."}
                                                        label={localize("API_KEY")}
                                                        required
                                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                                            this.setState({
                                                                import: {
                                                                    ...this.state.import,
                                                                    apiKey: newValue
                                                                }
                                                            });
                                                        }} />
                                                </FormControl>
                                            </div>
                                        )}
                                    </FormControl>
                                </FormSection>
                                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                                    <DefaultButton onClick={this.onCancelClick} text={localize("Avbryt")} />
                                    <PrimaryButton
                                        type={"submit"}
                                        disabled={(this.state.import.mode === 0 && (!this.state.import.ticket || this.state.import.ticket.remove)) || (this.state.import.mode === 1 && !this.state.import.apiKey)}>
                                        {localize("IMPORT_USERS")}
                                    </PrimaryButton>
                                </Stack>
                            </div>
                        )
                    }
                </PhoenixForm>
            </div>
        );
    }

    onCancelClick = () => {
        onFormClose(this.props.dispatch, () => {
            this.props.dispatch(setPopupForm({
                popupFormKey: "ImportUsers",
                isDisplayed: false
            }));
        });
    }

    handleImportCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveSuccess: false,
            saveError: errors,
            isLoadingModal: false,
        });
    };

    import = (e) => {
        e.preventDefault();

        this.setState({
            isLoadingModal: true,
            saveSuccess: false,
            saveError: [],
        }, () => {
            api.post("/api/v1/users/import-users", this.state.import).then(() => {
                this.props.dispatch(setPopupForm({
                    popupFormKey: "ImportUsers",
                    isDisplayed: false
                }));
            }).catch(this.handleImportCatch);
        })
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentUser: state.user.currentUser,
    currentInstance: state.instance.currentInstance,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "ImportUsers")
});

export default withRouter(connect(mapStateToProps)(ImportUsers));