import React, { useState } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import UnstyledButton from "src/components/UnstyledButton/UnstyledButton";
import moment from "moment";
import Visage2Icon from "../Visage2Icon/Visage2Icon";

interface IProps {
    chart: any;
    item: any;
    index: number;
    survey: any;
}

const RadarSidebarItem = (props: IProps) => {
    const [isVisible, setIsVisible] = useState(props.chart.isDatasetVisible(props.index));

    return (
        <div className="survey-wrapper" key={props.index}>
            <div className="row">
                <div className="survey-dot" style={{
                    backgroundColor: props.item.fillStyle.toString().toString().slice(0, -2)
                }}></div>
                <Label size="body-2" color="p-mid-grey-1">
                    {moment(props.item.createdDate).format("ll")}
                </Label>
            </div>
            <div className="row">
                <Label size="h3" weight="semi-bold" fontType="display">
                    {props.survey.answerCount + " " + localize("ANSWERS")}
                </Label>
                {/* <div className="percentage-change-container">
                    <Visage2Icon icon="arrow-up-3" color="p-green-1" size="extra-small" />
                    <Label color="p-green-1" size="body-2">78%</Label>
                </div> */}
                <div className="flex-spacer" />
                <UnstyledButton onClick={() => {
                    if (props.chart.isDatasetVisible(props.index)) {
                        props.chart.hide(props.index);
                    } else {
                        props.chart.show(props.index);
                    }

                    setIsVisible(!isVisible);
                }}>
                    <Visage2Icon icon={isVisible ? "eye" : "eye-slash"} color={isVisible ? "p-green-1" : "p-mid-grey-1"} size="small" />
                </UnstyledButton>
            </div>
        </div>
    )
}

export default RadarSidebarItem;
