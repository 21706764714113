import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { localize } from 'src/utils/l10n';
import logoUrl from './../../style/images/Logo_spintr_RGB_Blue-2.png'
import { connect } from 'react-redux';
import { IApplicationState } from 'src/reducer';
import './Sidebar.scss';
import getLightOrDarkColorBasedOnColor from 'src/utils/getLightOrDarkColorBasedOnColor';
import SpintrUserMenu from '../SpintrUserMenu/SpintrUserMenu';
import SidebarItem from './SidebarItem';
import { IPopupFormState } from 'src/reducers/ui';
import FormPopup from '../FormPopup/FormPopup';
import InstanceSettings from '../InstanceSettings/InstanceSettings';

interface IProps extends RouteComponentProps {
    currentInstance?: any
    currentUser?: any;
    popupFormState?: IPopupFormState;
    projects?: any[];
    adminMode?: boolean;
}

export interface ISidebarItem {
    icon: string;
    name: string;
    url?: string;
    children?: ISidebarItem[];
    hasBorderTop?: any;
    popupFormKey?: string;
    requiresProjects?: boolean;
}

class Sidebar extends Component<IProps> {
    renderItem(item: ISidebarItem, index: number) {
        return (
            <SidebarItem key={index} item={item} instanceColor={this.props.currentInstance.color} />
        )
    }

    getUserItems() {
        let items : ISidebarItem[] = [
            {
                icon: "home-1",
                name: localize("HOME"),
                url: "/radar"
            },
            {
                icon: "ruler&pen",
                name: localize("PROJECT_PLAN"),
                url: "/plan",
                requiresProjects: true
            },
            {
                icon: "layer",
                name: localize("VECTOR_ZOOM"),
                url: "/vector-details",
                requiresProjects: true
            },
            {
                icon: "data-2",
                name: localize("SURVEYS"),
                url: "/surveys",
                requiresProjects: true
            },
            {
                icon: "people",
                name: localize("USERS"),
                url: "/users"
            },
            {
                icon: "setting-4",
                name: localize("Installningar"),
                url: "/settings",
                popupFormKey: "InstanceSidebar"
            },
        ];

        if (this.props.projects.length === 0) {
            items = items.filter(x => !x.requiresProjects);
        }

        return items;
    }

    getAdminItems() {
        let items : ISidebarItem[] = [
            {
                icon: "home-1",
                name: localize("HOME"),
                url: "/admin"
            },
            {
                icon: "user-octagon",
                name: localize("CUSTOMERS"),
                url: "/admin/instances"
            },
            {
                icon: "note-1",
                name: localize("PROJECTS"),
                url: "/admin/projects"
            },
            {
                icon: "data-2",
                name: localize("SURVEYS"),
                url: "/admin/surveys"
            },
            {
                icon: "people",
                name: localize("USERS"),
                url: "/admin/users"
            },
            {
                icon: "setting-2",
                name: localize("AvanceradeInstallningar"),
                url: "/admin/advanced-settings"
            }
        ];

        return items;
    }

    getItems() {
        if (this.props.currentUser.isAdmin &&
            this.props.adminMode) {
            return this.getAdminItems();
        } else {
            return this.getUserItems();
        }
    }

    renderLogo() {
        let url = logoUrl;
        let color = "#FFFFFF";

        let preferliblyUseLightLogo = getLightOrDarkColorBasedOnColor(color,
            "a",
            "b") === "a";

        if (preferliblyUseLightLogo) {
            url = !!this.props.currentInstance.logoUrl ?
                this.props.currentInstance.logoUrl :
                this.props.currentInstance.colorLogoUrl;
        } else {
            url = !!this.props.currentInstance.colorLogoUrl ?
                this.props.currentInstance.colorLogoUrl :
                this.props.currentInstance.logoUrl;
        }

        if (!url) {
            url = logoUrl;
        }

        return (
            <Link to={"/"}>
                <div className="logo-wrapper">
                    <img src={url} alt="Spintr logotyp" />
                </div>
            </Link>
        )
    }

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup popupFormKey="InstanceSidebar">
                <InstanceSettings />
            </FormPopup>
        )
    }

    render() {
        return (
            <div className="sidebar">
                <div className="menu">
                    {this.renderLogo()}
                    <div className="menu-items">
                        {
                            this.getItems().map((item: any, index: number) => {
                                return this.renderItem(item, index);
                            })
                        }
                    </div>
                    <SpintrUserMenu
                        user={this.props.currentUser}
                        firstNameOnly={true}
                        displaySubText={false}
                    />
                </div>
                {this.renderFormPopup()}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    currentInstance: state.instance.currentInstance,
    currentUser: state.user.currentUser,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "InstanceSidebar"),
    projects: state.project.projects,
    adminMode: state.ui.adminMode
});

export default withRouter(connect(mapStateToProps)(Sidebar));

