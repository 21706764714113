import React, { useCallback, useEffect, useState } from 'react';
import { localize } from 'src/utils/l10n';
import FormSteps from '../FormSteps/FormSteps';
import { setPopupForm } from 'src/actions/ui';
import { connect, useDispatch } from 'react-redux';
import FormStep from '../FormSteps/FormStep';
import FormSection from '../FormSection/FormSection';
import FormControl from '../FormControl/FormControl';
import { ChoiceGroup, DatePicker, DayOfWeek, DefaultButton, Dropdown, PrimaryButton, SpinButton, Stack, TextField, TimePicker } from '@fluentui/react';
import { validateRequiredTextField } from 'src/utils';
import './ProjectForm.scss';
import api from 'src/api/SpintrApi';
import { AxiosResponse } from 'axios';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import Label from '../Label/Label';
import Loader from '../Loader/Loader';
import StringFormPopup from '../StringFormPopup/StringFormPopup';
import ErrorMessagebar from '../Messagebars/ErrorMessagebar';
import { setConfirmPopup } from 'src/actions/popup';
import getDatePickerLanguageStrings from 'src/utils/getDatePickerLanguageStrings';
import formatDatePickerDate from 'src/utils/formatDatePickerDate';
import { fetchProjects } from 'src/actions/project';
import PopupHeader from '../PopupHeader/PopupHeader';
import ProjectStatusSelector from '../ProjectStatusSelector/ProjectStatusSelector';
import { IApplicationState } from 'src/reducer';
import FormTokenizedObjectInput from '../FormTokenizedObjectInput/FormTokenizedObjectInput';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';
import AnimatedDots from '../AnimatedDots/AnimatedDots';

interface IProps {
    id?: number;
    instanceId?: number;
    chooseInstance?: boolean;
    instanceEnableAI?: boolean;
}

export interface IFormStep {
    title: string;
    cancelButtonText: string;
    saveButtonText: string;
}

export interface IProject {
    id: number;
    name: string;
    startDate: Date;
    endDate?: Date;
    purpose: string;
    goal: string;
    isStrategicGoal: boolean;
    strategicGoal?: string;
    strategicGoalChallenges?: string;
    surveyInterval: number;
    surveyIntervalFreeChoiceDays: number;
    baselineDate?: Date,
    questionModelling: number;
    vectors: any;
    status: number;
    instanceId?: number;
    instance?: any;
    isAnonymous: boolean;
}

interface IState {
    steps: IFormStep[];
    step: number;
    item: IProject;
    projectTypes: any[];
    activeVectorIndex: number;
    activeQuestionIndex: number;
    displayVectorForm: boolean;
    isLoading: boolean;
    saveError: any[];
    displayQuestionPreview: boolean;
    isNew: boolean;
    enableAI: boolean;
}

const removeItemAtIndex = (items: any[], index: number) => {
    const result = [...items];

    result.splice(index, 1);

    return result;
}

const ProjectForm = (props: IProps) => {
    const dispatch = useDispatch();

    const isNew = !props.id || props.id === 0;

    const [state, setState] = useState<IState>({
        steps: [{
            title: localize("PROJECT_INFORMATION"),
            cancelButtonText: localize("Avbryt"),
            saveButtonText: localize("NEXT")
        }, {
            title: isNew ? localize("PROJECT_TYPE_AND_VECTORS") : localize("VECTORS"),
            cancelButtonText: localize("PREVIOUS"),
            saveButtonText: localize("NEXT")
        }, {
            title: localize("Installningar"),
            cancelButtonText: localize("PREVIOUS"),
            saveButtonText: isNew ? localize("LAUNCH_PROJECT") : localize("SAVE")
        }],
        step: 0,
        item: {
            id: 0,
            name: "",
            startDate: new Date(),
            purpose: "",
            goal: "",
            isStrategicGoal: false,
            surveyInterval: 0,
            surveyIntervalFreeChoiceDays: 7,
            questionModelling: 0,
            vectors: [],
            status: 0,
            isAnonymous: false
        },
        projectTypes: [],
        activeVectorIndex: -1,
        activeQuestionIndex: -1,
        displayVectorForm: false,
        isLoading: !isNew,
        saveError: [],
        displayQuestionPreview: false,
        isNew: isNew,
        enableAI: props.chooseInstance ? false : props.instanceEnableAI,
    });

    useEffect(() => {
        api.get("/api/v1/projects/projecttypes?includeQuestions=true&translate=true&take=1000").then((response: AxiosResponse) => {
            setState(s => ({
                ...s,
                projectTypes: response.data.map((item: any) => {
                    return {
                        ...item,
                        vectors: item.vectors.map((vector: any) => {
                            return {
                                ...vector,
                                questionStatus: 3
                            }
                        })
                    }
                })
            }));
        }).catch(() => {});

        if (!state.isNew) {
            api.get("/api/v1/projects/" + props.id, {
                params: {
                    instanceId: props.instanceId,
                },
            }).then((response: AxiosResponse) => {
                setState(s => ({
                    ...s,
                    isLoading: false,
                    item: {
                        ...response.data,
                        startDate: new Date(response.data.startDate),
                        endDate: response.data.endDate ? new Date(response.data.endDate) : undefined,
                        baselineDate: new Date(response.data.baselineDate),
                        vectors: response.data.vectors.map((v: any) => {
                            return {
                                ...v,
                                questionStatus: 3
                            }
                        })
                    }
                }));
            }).catch(() => {});
        }
    }, []);

    useEffect(() => {
        if (!state.item.instanceId) {
            return;
        }

        api.get("/api/v1/instances/" + state.item.instanceId).then((response) => {
            setState(s => ({
                ...s,
                enableAI: response.data.enableAI,
            }));
        });
    }, [state.item.instanceId]);

    const validateStep = (step: number) => {
        let isOk = true;

        if (step === 0) {
            if (!state.item.name ||
                !state.item.goal ||
                !state.item.purpose ||
                (state.item.isStrategicGoal && (!state.item.strategicGoal || !state.item.strategicGoalChallenges)) ||
                (props.chooseInstance && !state.item.instance)) {
                setState(s => ({
                    ...s,
                    saveError: ["VALIDATION_FILL_REQUIRED_FIELDS"]
                }));

                isOk = false;
            }
        } else if (step === 1) {
            const errors : string[] = [];

            if (state.item.vectors.length < 3) {
                errors.push("VALIDATION_VECTOR_COUNT");
            }

            if (state.enableAI && !!state.item.vectors.find((x: any) => x.questionStatus !== 3)) {
                errors.push("VALIDATION_GENERATE_QUESTIONS");
            }

            if (!state.enableAI && state.item.vectors.some((v) => v.questions.filter((q) => q.text.length > 0).length < 10)) {
                errors.push("VALIDATION_QUESTION_COUNT");
            }
    
            if (errors.length > 0) {
                setState(s => ({
                    ...s,
                    saveError: errors
                }));

                isOk = false;
            }
        }

        return isOk;
    }

    const close = useCallback(() => {
        onFormClose(dispatch, () => {
            dispatch(setPopupForm({
                popupFormKey: "Project",
                isDisplayed: false
            }));
        });
    }, []);

    const save = useCallback(() => {
        setState(s => ({
            ...s,
            isLoading: true
        }));

        const errors : string[] = [];
        
        let fallbackStep = -1;

        if (!state.item.name ||
            !state.item.baselineDate ||
            !state.item.goal ||
            !state.item.purpose ||
            (state.item.isStrategicGoal && (!state.item.strategicGoal || !state.item.strategicGoalChallenges))) {
            errors.push("VALIDATION_FILL_REQUIRED_FIELDS");
            fallbackStep = 0;
        }

        if (state.item.vectors.length < 3) {
            errors.push("VALIDATION_VECTOR_COUNT");
            fallbackStep = fallbackStep === -1 ? 1 : fallbackStep;
        }

        if (state.enableAI && !!state.item.vectors.find((x: any) => x.questionStatus !== 3)) {
            errors.push("VALIDATION_GENERATE_QUESTIONS");
            fallbackStep = fallbackStep === -1 ? 1 : fallbackStep;
        }

        if (errors.length > 0) {
            setState(s => ({
                ...s,
                isLoading: false,
                saveError: errors,
                step: fallbackStep
            }));

            return;
        }

        setState(s => ({
            ...s,
            isLoading: true
        }));

        api.post("/api/v1/projects", state.item).then((response: AxiosResponse) => {
            dispatch(fetchProjects());

            dispatch(setPopupForm({
                popupFormKey: "Project",
                isDisplayed: false
            }));

            api.post("/api/v1/language/translate").then(() => {}).catch(() => {});
        }).catch(() => {
            setState(s => ({
                ...s,
                isLoading: false,
                saveError: ["TeknisktFel"]
            }));
        })
    }, [state.item]);

    const generateQuestions = useCallback(() => {
        for (let i = 0; i < state.item.vectors.length; i++) {
            const v = state.item.vectors[i];

            if (v.questionStatus > 2) {
                continue;
            }

            const envelope = {
                projectName: state.item.name,
                projectPurpose: state.item.purpose,
                projectGoal: state.item.goal,
                strategicGoal: state.item.isStrategicGoal ?
                    state.item.strategicGoal :
                    "",
                strategicGoalChallenges: state.item.isStrategicGoal ?
                    state.item.strategicGoalChallenges :
                    "",
                vectors: [v.name]
            };

            setState(s => ({
                ...s,
                item: {
                    ...s.item,
                    vectors: s.item.vectors.map((x: any, index: number) => {
                        if (index === i) {
                            return {
                                ...x,
                                questionStatus: 2
                            }
                        }
                        
                        return x;
                    })
                }
            }));

            api.post("/api/v1/projects/generatequestions", envelope).then((response: AxiosResponse) => {
                setState(s => ({
                    ...s,
                    item: {
                        ...s.item,
                        vectors: s.item.vectors.map((x: any, index: number) => {
                            if (index === i) {
                                return {
                                    ...x,
                                    questionStatus: 3,
                                    questions: response.data[0].questions.map((text: string) => {
                                        return {
                                            text
                                        }
                                    })
                                }
                            }
                            
                            return x;
                        })
                    }
                }));
            }).catch(() => {
                setState(s => ({
                    ...s,
                    item: {
                        ...s.item,
                        vectors: s.item.vectors.map((x: any, index: number) => {
                            if (index === i) {
                                return {
                                    ...x,
                                    questionStatus: 0
                                }
                            }
                            
                            return x;
                        })
                    }
                }));
            });
        }
    }, [state.item]);

    const isGeneratingQuestions = !!state.item.vectors.find(x => x.questionStatus === 2);

    const getProjectType = () => {
        for (let pt of state.projectTypes) {
            if (state.item.vectors.map(x => x.id).join(" ") === pt.vectors.map(x => x.id).join(" ")) {
                return pt.id;
            }
        }

        return 0;
    }

    const openAddQuestionPopup = () => {
        dispatch(setPopupForm({
            popupFormKey: "StringForm",
            id: 0,
            title: localize("ADD_QUESTION"),
            isDisplayed: true
        }));
    }

    if (state.isLoading) {
        return (
            <Loader />
        )
    }

    if (state.activeVectorIndex > -1 && state.displayQuestionPreview) {
        const activeVector = state.item.vectors[state.activeVectorIndex];
        const canEdit = state.isNew || !activeVector.id;

        return (
            <div className="ProjectForm questions">
                <div>
                    {state.enableAI && activeVector.questions.length === 0 && (
                        <Label size="body-2" color="p-mid-grey-5">{localize("NO_QUESTIONS_ERROR")}</Label>
                    )}
                    {(!state.enableAI || activeVector.questions.length > 0) && (
                        <div className="table">
                            <div className="row header">
                                <div className="cell">
                                    <Label size="body-3" color="p-mid-grey-1">{localize("QUESTION")}</Label>
                                </div>
                            </div>
                            {activeVector.questions.map((item: any, index: number) => {
                                return (
                                    <UnstyledButton className="row" disabled={!canEdit} key={index} onClick={() => {
                                        setState(s => ({
                                            ...s,
                                            activeQuestionIndex: index
                                        }));

                                        dispatch(setPopupForm({
                                            popupFormKey: "StringForm",
                                            id: 0,
                                            title: localize("EDIT_QUESTION"),
                                            isDisplayed: true
                                        }));
                                    }}>
                                        <div className="cell">
                                            <Label
                                                size="body-2"
                                                color={!item.text ? "p-light-grey-10" : undefined}
                                            >
                                                {item.text || localize("WRITE_A_QUESTION")}
                                            </Label>
                                        </div>
                                        {canEdit && (
                                            <div className="actions">
                                                <UnstyledButton
                                                    title={localize("Redigera")}
                                                    onClick={() => {
                                                        setState(s => ({
                                                            ...s,
                                                            activeQuestionIndex: index
                                                        }));

                                                        dispatch(setPopupForm({
                                                            popupFormKey: "StringForm",
                                                            id: 0,
                                                            title: localize("EDIT_QUESTION"),
                                                            isDisplayed: true
                                                        }));
                                                    }}>
                                                    <Visage2Icon icon="edit" size="small" />
                                                </UnstyledButton>
                                            </div>
                                        )}
                                    </UnstyledButton>
                                )
                            })}
                            {!state.enableAI && canEdit && (
                                <div className="table-footer">
                                    <UnstyledButton className="add-button" onClick={openAddQuestionPopup}>
                                        <Visage2Icon icon="add" color="p-green-1" />
                                        <Label
                                            size="body-2"
                                            weight="medium"
                                            color="p-green-1"
                                            fontType="display"
                                        >
                                            {localize("ADD_QUESTION")}
                                        </Label>
                                    </UnstyledButton>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="questions-footer">
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                        <DefaultButton onClick={() => {
                            dispatch(setPopupForm({
                                popupFormKey: "Project",
                                title: localize("START_NEW_PROJECT"),
                                hideCloseButton: false,
                                hideHeader: true
                            }));

                            setState(s => ({
                                ...s,
                                activeVectorIndex: -1,
                                displayQuestionPreview: false
                            }));
                        }} text={localize("GO_BACK")} />
                    </Stack>
                </div>
                <StringFormPopup
                    multiline
                    onCancelClick={() => {
                        setState(s => ({
                            ...s,
                            activeQuestionIndex: -1
                        }));

                        dispatch(setPopupForm({
                            popupFormKey: "StringForm",
                            isDisplayed: false
                        }));
                    }}
                    onSaveClick={(value: string) => {
                        setState(s => ({
                            ...s,
                            activeQuestionIndex: -1,
                            item: {
                                ...s.item,
                                vectors: s.item.vectors.map((v: any, vIndex: number) => {
                                    if (vIndex === state.activeVectorIndex) {
                                        if (state.activeQuestionIndex === -1) {
                                            return {
                                                ...v,
                                                questions: [...v.questions, {
                                                    text: value
                                                }],
                                            }
                                        }
                                        return {
                                            ...v,
                                            questions: v.questions.map((q: any, qIndex: number) => {
                                                if (qIndex === state.activeQuestionIndex) {
                                                    return {
                                                        ...q,
                                                        text: value
                                                    }
                                                } else {
                                                    return q;
                                                }
                                            })
                                        }
                                    } else {
                                        return v;
                                    }
                                })
                            }
                        }));

                        dispatch(setPopupForm({
                            popupFormKey: "StringForm",
                            isDisplayed: false
                        }));
                    }}
                    value={state.activeVectorIndex === -1 || state.activeQuestionIndex === -1 ? "" : state.item.vectors[state.activeVectorIndex].questions[state.activeQuestionIndex].text}
                    title={localize("EDIT_QUESTION")}
                    label={localize("QUESTION")} />
            </div>
        )
    }

    return (
        <div className="ProjectForm">
            <PhoenixForm data={state.item}>
            <PopupHeader
                text={state.isNew ? localize("START_NEW_PROJECT") : localize("EDIT_PROJECT")}
                renderFooterContent={state.isNew ? undefined : () => {
                    return (
                        <ProjectStatusSelector
                            projectId={state.item.id}
                            project={state.item}
                            onStatusChange={(status: number) => {
                                setState(s => ({
                                    ...s,
                                    item: {
                                        ...s.item,
                                        status
                                    }
                                }));
                            }} />
                    )
                }}
                onClose={() => {
                    close();
                }} />
            {state.saveError.length > 0 && (
                <ErrorMessagebar
                    errorList={state.saveError}
                    onDismiss={() => {
                        setState(s => ({
                            ...s,
                            saveError: []
                        }));
                    }}
                />
            )}
            <FormSteps
                steps={state.steps}
                step={state.step}
                onCancelClick={() => {
                    if (state.step === 0) {
                        close();
                    } else {
                        setState(s => ({
                            ...s,
                            step: s.step - 1
                        }));
                    }
                }}
                onSaveClick={() => {
                    if (state.step === (state.steps.length - 1)) {
                        save();
                    } else {
                        const isOk = validateStep(state.step);

                        if (!isOk) {
                            return;
                        }

                        if (state.step === 1 && !state.item.baselineDate) {
                            setState((s) => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    baselineDate: new Date(),
                                },
                            }));
                        }

                        setState(s => ({
                            ...s,
                            step: s.step + 1,
                            saveError: []
                        }));
                    }
                }}
                onStepChange={(step: number) => {
                    let isForward = step > state.step;

                    if (isForward) {
                        let isOk = validateStep(state.step);

                        if (!isOk) {
                            return;
                        }

                        if (step === 2 && state.step === 0) {
                            isOk = validateStep(1);
                        }

                        if (!isOk) {
                            return;
                        }

                        if (step === 2 && !state.item.baselineDate) {
                            setState((s) => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    baselineDate: new Date(),
                                },
                            }));
                        }
                    }

                    setState(s => ({
                        ...s,
                        step: step
                    }));
                }}>
                <FormStep step={0}>
                    <FormSection>
                        {props.chooseInstance && (
                            <FormTokenizedObjectInput
                                url={"/api/v1/instances"}
                                label={localize("CUSTOMER")}
                                items={
                                    state.item.instance
                                        ? [state.item.instance]
                                        : []
                                }
                                onChange={(instances) => {
                                    setState((s) => ({
                                        ...s,
                                        item: {
                                            ...state.item,
                                            instance: instances[0],
                                            instanceId: instances[0]?.key,
                                        },
                                    }));
                                }}
                                required
                                itemLimit={1}
                            />
                        )}
                        <FormControl>
                            <TextField
                                value={state.item.name}
                                className="textField"
                                label={localize("PROJECT_NAME")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            name: newValue
                                        }
                                    }));
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <div className="form-row">
                            <FormControl>
                                <DatePicker
                                    label={localize("Startdatum")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={formatDatePickerDate}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={state.item.startDate}
                                    isRequired={true}
                                    onSelectDate={(date: Date) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                startDate: date
                                            }
                                        }));
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <DatePicker
                                    label={localize("Slutdatum")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={formatDatePickerDate}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={state.item.endDate}
                                    isRequired={false}
                                    onSelectDate={(date: Date) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                endDate: date
                                            }
                                        }));
                                    }}
                                />
                            </FormControl>
                        </div>
                        <FormControl>
                            <TextField
                                value={state.item.purpose}
                                className="textField"
                                label={localize("PROJECT_PURPOSE")}
                                multiline
                                autoAdjustHeight
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            purpose: newValue
                                        }
                                    }));
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={state.item.goal}
                                className="textField"
                                label={localize("PROJECT_GOAL")}
                                multiline
                                autoAdjustHeight
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            goal: newValue
                                        }
                                    }));
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl label={localize("IS_STRATEGIC_GOAL")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.isStrategicGoal ? "0" : "1"}
                                options={[
                                    { key: "0", text: localize("YES") },
                                    { key: "1", text: localize("NO") },
                                ]}
                                onChange={(e, v) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            isStrategicGoal: v.key === "0"
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                        {state.item.isStrategicGoal && (
                            <div>
                                <FormControl>
                                    <TextField
                                        value={state.item.strategicGoal}
                                        className="textField"
                                        label={localize("STRATEGIC_GOAL")}
                                        multiline
                                        autoAdjustHeight
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    strategicGoal: newValue
                                                }
                                            }));
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        value={state.item.strategicGoalChallenges}
                                        className="textField"
                                        label={localize("STRATEGIC_GOAL_CHALLENGES")}
                                        multiline
                                        autoAdjustHeight
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    strategicGoalChallenges: newValue
                                                }
                                            }));
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                            </div>
                        )}
                    </FormSection>
                </FormStep>
                <FormStep step={1}>
                    <FormSection>
                        {state.projectTypes.length > 0 && state.isNew && (
                            <FormControl>
                                <Dropdown
                                    label={localize("PROJECT_TYPE")}
                                    selectedKey={getProjectType()}
                                    disabled={!state.isNew}
                                    required
                                    onChange={(_e, v) => {
                                        const id = Number(v.key);

                                        if (id === 0) {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    vectors: []
                                                }
                                            }));

                                            return;
                                        }

                                        const pt = state.projectTypes.find(x => x.id === id);

                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                vectors: pt.vectors
                                            }
                                        }));
                                    }}
                                    options={[
                                        ...state.projectTypes.map((pt: any) => {
                                            return {
                                                key: pt.id,
                                                text: pt.name
                                            }
                                        }),
                                        {
                                            key: 0,
                                            text: localize("CUSTOM")
                                        }
                                    ]}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                        )}
                        <div className="table">
                            {state.item.vectors.length > 0 && (
                                <div className="row header">
                                    <div className="cell">
                                        <Label size="body-3" color="p-mid-grey-1">{localize("VECTOR")}</Label>
                                    </div>
                                    <div className="cell">
                                        <Label size="body-3" color="p-mid-grey-1">{localize("QUESTION_STATUS")}</Label>
                                    </div>
                                </div>
                            )}
                            {state.item.vectors.map((item: any, index: number) => {
                                const canEdit = state.isNew || !item.id;

                                return (
                                    <div className="row" key={index}>
                                        <div className="cell">
                                            <Label size="body-2" title={item.name}>{item.name}</Label>
                                        </div>
                                        <div className="cell">
                                            <Label size="body-2">
                                                <Stack horizontal>
                                                    {!state.enableAI && item.questionStatus === 1 ?
                                                        `${item.questions.filter((q) => q.text.length > 0).length} ${localize(item.questions.filter((q) => q.text.length > 0).length === 1 ? "QUESTION" : "QUESTIONS").toLowerCase()}` :
                                                            localize("VECTOR_GENERATE_STATUS_" + item.questionStatus)
                                                    }
                                                    {item.questionStatus === 2 && (<AnimatedDots />)}
                                                </Stack>
                                            </Label>
                                        </div>
                                        <div className="actions">
                                            <UnstyledButton
                                                title={localize("QUESTIONS_PREVIEW")}
                                                onClick={() => {
                                                    setState(s => ({
                                                        ...s,
                                                        activeVectorIndex: index,
                                                        displayQuestionPreview: true
                                                    }));

                                                    dispatch(setPopupForm({
                                                        popupFormKey: "Project",
                                                        title: item.name,
                                                        hideCloseButton: true,
                                                        hideHeader: false
                                                    }));
                                                }}>
                                                <Visage2Icon icon="eye" size="small" />
                                            </UnstyledButton>
                                            {canEdit && (
                                                <UnstyledButton
                                                    title={localize("Redigera")}
                                                    onClick={() => {
                                                        setState(s => ({
                                                            ...s,
                                                            displayVectorForm: true,
                                                            activeVectorIndex: index
                                                        }));

                                                        dispatch(setPopupForm({
                                                            popupFormKey: "StringForm",
                                                            id: 0,
                                                            title: localize("EDIT_VECTOR"),
                                                            isDisplayed: true
                                                        }));
                                                    }}>
                                                    <Visage2Icon icon="edit" size="small" />
                                                </UnstyledButton>
                                            )}
                                            {canEdit && (
                                                <UnstyledButton
                                                    title={localize("TaBort")}
                                                    onClick={() => {
                                                        dispatch(setConfirmPopup({
                                                            isOpen: true,
                                                            title: "",
                                                            message: localize("ArDuSakerPaAttDuVillTaBortDennaPost"),
                                                            onConfirm: async () => {
                                                                setState(s => ({
                                                                    ...s,
                                                                    item: {
                                                                        ...s.item,
                                                                        vectors: removeItemAtIndex(s.item.vectors, index)
                                                                    }
                                                                }));
                                                            },
                                                        }));
                                                    }}>
                                                    <Visage2Icon icon="trash" size="small" />
                                                </UnstyledButton>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="table-footer">
                                <div>
                                    <UnstyledButton className="add-button" onClick={() => {
                                        setState(s => ({
                                            ...s,
                                            displayVectorForm: true
                                        }));

                                        dispatch(setPopupForm({
                                            popupFormKey: "StringForm",
                                            id: 0,
                                            title: localize("ADD_VECTOR"),
                                            isDisplayed: true
                                        }));
                                    }}>
                                        <Visage2Icon icon="add" color="p-green-1" />
                                        <Label
                                            size="body-2"
                                            weight="medium"
                                            color="p-green-1"
                                            fontType="display">{localize("ADD_VECTOR")}</Label>
                                    </UnstyledButton>
                                </div>
                                {state.enableAI && state.item.vectors.filter(x => x.questionStatus < 3).length > 0 && (
                                    <div>
                                        <Label
                                            size="body-3"
                                            color="p-mid-grey-1"
                                            weight="medium">
                                            {localize("GENERATE_DESCRIPTION")}
                                        </Label>
                                        <div>
                                            <PrimaryButton
                                                disabled={isGeneratingQuestions}
                                                onClick={() => {
                                                    generateQuestions();
                                                }}>
                                                {localize(isGeneratingQuestions ? "VECTOR_GENERATE_STATUS_2" : "GENERATE_QUESTIONS")}
                                                {isGeneratingQuestions && <AnimatedDots />}
                                            </PrimaryButton>
                                        </div>
                                        {isGeneratingQuestions && (
                                            <Loader />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </FormSection>
                </FormStep>
                <FormStep step={2}>
                    <FormSection>
                        <FormControl label={localize("SURVEY_INTERVAL")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.surveyInterval.toString()}
                                options={[
                                    { key: "0", text: localize("EVERY_MONTH") },
                                    { key: "1", text: "2 " + localize("MONTHS") },
                                    { key: "2", text: "3 " + localize("MONTHS") },
                                    { key: "3", text: localize("DAYS") },
                                ]}
                                onChange={(e, v) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            surveyInterval: Number(v.key)
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                        {state.item.surveyInterval === 3 && (
                            <FormControl>
                                <SpinButton
                                    className="spinButton"
                                    defaultValue={state.item.surveyIntervalFreeChoiceDays.toString()}
                                    min={1}
                                    step={1}
                                    onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                surveyIntervalFreeChoiceDays: Number(newValue)
                                            }
                                        }))
                                    }}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <div className="form-row">
                            <DatePicker
                                label={localize("BASELINE_DATE")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={state.item.baselineDate}
                                isRequired={true}
                                onSelectDate={(date: Date) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            baselineDate: date
                                        }
                                    }));
                                }}
                            />
                            {/* TimePicker doesn't seem to re-render properly, this should force it to re-render once baselineDate is set */}
                            {state.item.baselineDate &&
                                <TimePicker
                                    label={localize("ValjTid")}
                                    placeholder={localize("ValjTid")}
                                    ariaLabel={localize("ValjTid")}
                                    increments={15}
                                    allowFreeform
                                    dateAnchor={state.item.baselineDate}
                                    value={state.item.baselineDate}
                                    timeRange={{start: 0, end: 24}}
                                    onChange={(ev, time) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                baselineDate: time,
                                            }
                                        }));
                                    }}
                                />
                            }
                            </div>
                        </FormControl>
                        {state.isNew && (
                            <FormControl label={localize("QUESTION_MODELLING")}>
                                <ChoiceGroup
                                    defaultSelectedKey={state.item.questionModelling.toString()}
                                    options={[
                                        { key: "0", text: localize("STRAIGHT") },
                                        { key: "1", text: localize("PARTLY_RANDOM") },
                                        { key: "2", text: localize("RANDOM") },
                                    ]}
                                    onChange={(e, v) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                questionModelling: Number(v.key)
                                            }
                                        }))
                                    }}
                                    required={true}
                                    aria-required={true}
                                />
                            </FormControl>
                        )}
                        <FormControl label={localize("ANON_SURVEYS")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.isAnonymous ? "1" : "0"}
                                options={[
                                    { key: "0", text: localize("NO") },
                                    { key: "1", text: localize("YES") }
                                ]}
                                onChange={(e, v) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            isAnonymous: v.key === "1"
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                    </FormSection>
                </FormStep>
            </FormSteps>
            <StringFormPopup
                onCancelClick={() => {
                    setState(s => ({
                        ...s,
                        displayVectorForm: false
                    }));

                    dispatch(setPopupForm({
                        popupFormKey: "StringForm",
                        isDisplayed: false
                    }));
                }}
                value={state.activeVectorIndex > -1 ? state.item.vectors[state.activeVectorIndex].name : ""}
                onSaveClick={(value: string) => {
                    if (state.activeVectorIndex > -1) {
                        setState(s => ({
                            ...s,
                            activeVectorIndex: -1,
                            displayVectorForm: false,
                            item: {
                                ...s.item,
                                vectors: s.item.vectors.map((v: any, index: number) => {
                                    if (index === state.activeVectorIndex) {
                                        return {
                                            ...v,
                                            name: value
                                        }
                                    } else {
                                        return v;
                                    }
                                })
                            }
                        }));
                    } else {
                        setState(s => ({
                            ...s,
                            displayVectorForm: false,
                            activeVectorIndex: -1,
                            item: {
                                ...s.item,
                                vectors: [
                                    ...s.item.vectors,
                                    {
                                        name: value,
                                        questionStatus: 1,
                                        questions: state.enableAI ? [] : Array.from({ length: 10 }, () => ({ text: "" })),
                                    }
                                ]
                            }
                        }));
                    }

                    dispatch(setPopupForm({
                        popupFormKey: "StringForm",
                        isDisplayed: false
                    }));
                }}
                title={state.activeVectorIndex > -1 ? localize("EDIT_VECTOR") : localize("ADD_VECTOR")}
                label={localize("NAME")} />
            </PhoenixForm>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    instanceEnableAI: state.instance.currentInstance.enableAI,
    questionPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "StringForm")
});

export default connect(mapStateToProps)(ProjectForm);
